@charset "utf-8";
@import "_base.scss";
@import "_mixin.scss";

//loader
#loader-bg {
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	background: #eaeaea;
	z-index: 100000;
}
#loader {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	width: 80px;
	height: 80px;
	margin-top: -40px;
	margin-left: -40px;
	text-align: center;
	z-index: 99999;
}
.noscript_outer {
	display: none;
	position: absolute;
	font-size: 12px;
	z-index:99999;
	left: 0;top: 0;
	border-bottom: 1px solid #eee;
	background: #fafafa;
	padding: 1em 5%;
	width: 100%;
	text-align: center;
	color: #4AA3DF;
	box-sizing: border-box;
}
