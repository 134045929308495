html {
  font-size: 100%; }

body {
  font-size: 16px;
  font-size: 1rem; }

body {
  line-height: 1.75;
  font-size: 16px;
  font-size: 1rem; }
  @media screen and (max-width: 979px) {
    body {
      font-size: 14px;
      font-size: 0.875rem; } }

#loader-bg {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #eaeaea;
  z-index: 100000; }

#loader {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  text-align: center;
  z-index: 99999; }

.noscript_outer {
  display: none;
  position: absolute;
  font-size: 12px;
  z-index: 99999;
  left: 0;
  top: 0;
  border-bottom: 1px solid #eee;
  background: #fafafa;
  padding: 1em 5%;
  width: 100%;
  text-align: center;
  color: #4AA3DF;
  box-sizing: border-box; }

/*-----------------------------------------------
 __layout
-----------------------------------------------*/
#wrap {
  min-height: 100%;
  max-height: 100%;
  position: relative;
  background: #29a4d1;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMTAwJSIgeDI9IjEwMCUiIHkyPSIwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMjlhNGQxIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMjglIiBzdG9wLWNvbG9yPSIjMjRiNTlmIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNDQlIiBzdG9wLWNvbG9yPSIjMjRiNTlmIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iODklIiBzdG9wLWNvbG9yPSIjNjBhYmU1IiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
  background: -moz-linear-gradient(45deg, #29a4d1 0%, #24b59f 28%, #24b59f 44%, #60abe5 89%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #29a4d1), color-stop(28%, #24b59f), color-stop(44%, #24b59f), color-stop(89%, #60abe5));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(45deg, #29a4d1 0%, #24b59f 28%, #24b59f 44%, #60abe5 89%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(45deg, #29a4d1 0%, #24b59f 28%, #24b59f 44%, #60abe5 89%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(45deg, #29a4d1 0%, #24b59f 28%, #24b59f 44%, #60abe5 89%);
  /* IE10+ */
  background: linear-gradient(45deg, #29a4d1 0%, #24b59f 28%, #24b59f 44%, #60abe5 89%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#29a4d1', endColorstr='#60abe5',GradientType=1 );
  /* IE6-8 fallback on horizontal gradient */
  background-size: 100% 500px;
  background-repeat: no-repeat; }
  #wrap.home {
    background: #9FADAD url(../images/base/1st_view_bg_001.jpg) no-repeat;
    background-attachment: fixed;
    background-size: 1600px;
    background-position: 50% -60px; }
    @media screen and (max-width: 1600px) {
      #wrap.home {
        background-size: 1600px; } }
    @media screen and (max-width: 960px) {
      #wrap.home {
        background-size: 1400px; } }
    @media screen and (max-width: 768px) {
      #wrap.home {
        background-size: 1200px; } }
    @media screen and (max-width: 500px) {
      #wrap.home {
        background-size: 1000px; } }
  #wrap.android {
    background-attachment: scroll; }
  #wrap.invoice {
    background: #60abe5; }

html.ie9 #wrap.gradient {
  filter: none; }

#contents {
  width: 100%; }

.row {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  position: relative;
  padding: 0 5%;
  box-sizing: border-box; }
  .row.txt_ac {
    text-align: center; }

.dnon {
  display: none; }

#section_header.common {
  padding: 5em 0; }
  @media screen and (max-width: 660px) {
    #section_header.common {
      padding-bottom: 3em; } }
#section_header .description {
  padding: 3em 0;
  line-height: 1.7; }
#section_header .tcf {
  color: #fff; }
#section_header .tac {
  text-align: center; }

.title {
  line-height: 1.2; }
  .title.sch_ttl {
    font-size: 46px;
    font-size: 2.875rem; }
    @media screen and (max-width: 979px) {
      .title.sch_ttl {
        font-size: 38px;
        font-size: 2.375rem; } }
    @media screen and (max-width: 660px) {
      .title.sch_ttl {
        font-size: 30px;
        font-size: 1.875rem; } }
    .title.sch_ttl.mid {
      font-size: 30px;
      font-size: 1.875rem; }
      @media screen and (max-width: 979px) {
        .title.sch_ttl.mid {
          font-size: 26px;
          font-size: 1.625rem; } }
      @media screen and (max-width: 660px) {
        .title.sch_ttl.mid {
          font-size: 22px;
          font-size: 1.375rem; } }
    .title.sch_ttl.invoice {
      font-size: 30px;
      font-size: 1.875rem; }
      @media screen and (max-width: 979px) {
        .title.sch_ttl.invoice {
          font-size: 26px;
          font-size: 1.625rem; } }
      @media screen and (max-width: 660px) {
        .title.sch_ttl.invoice {
          font-size: 22px;
          font-size: 1.375rem; } }

#section_contents {
  background: #fff; }
  #section_contents .sec_padding {
    padding-top: 5em;
    padding-bottom: 5em; }
    @media screen and (max-width: 767px) {
      #section_contents .sec_padding {
        padding-top: 3em;
        padding-bottom: 3em; } }
  #section_contents .intro p {
    margin-bottom: .8em; }
  #section_contents .intro .invoice_intro {
    font-size: 14px; }
    @media screen and (max-width: 767px) {
      #section_contents .intro .invoice_intro {
        font-size: 12px; } }
    #section_contents .intro .invoice_intro p {
      margin-bottom: .5em; }
  #section_contents .tac {
    text-align: center; }
    @media screen and (max-width: 979px) {
      #section_contents .tac {
        text-align: left; } }

.column_wrapper {
  box-sizing: border-box;
  width: 100%; }
  .column_wrapper.di_tbl {
    display: table; }
  .column_wrapper.tbl_lay_fix {
    table-layout: fixed; }
  .column_wrapper.tal {
    text-align: left; }
  .column_wrapper.tac {
    text-align: center; }
  .column_wrapper.padt {
    padding-top: 5em; }
  .column_wrapper .column {
    box-sizing: border-box; }
    .column_wrapper .column.di_tblc {
      display: table-cell; }
    .column_wrapper .column.di_inb {
      display: inline-block; }
      .column_wrapper .column.di_inb.box3 {
        width: 33.3%; }
    .column_wrapper .column.tal {
      text-align: left; }
    .column_wrapper .column.tac {
      text-align: center; }
    .column_wrapper .column.pd {
      padding: 1em 2em; }
  .column_wrapper .btn {
    text-decoration: none;
    display: block;
    border: 2px solid #24B59F;
    padding: 1em;
    color: #24B59F; }

.draft .section {
  margin-bottom: 4em;
  padding-bottom: 4em;
  border-bottom: 1px solid #eee; }
.draft p {
  margin-bottom: 1em; }
.draft .secttl {
  margin-bottom: 1em; }
.draft .subttl {
  font-weight: bold; }

/*-----------------------------------------------
 __header
-----------------------------------------------*/
#header_global {
  max-width: 1600px;
  margin: 0 auto; }
  @media screen and (max-width: 768px) {
    #header_global.home .main_logo {
      padding: 0;
      z-index: 99;
      background: rgba(0, 0, 0, 0.03); }
      @media screen and (max-width: 500px) {
        #header_global.home .main_logo {
          background: rgba(0, 0, 0, 0.2); } } }
  #header_global.home .logo {
    font-size: 60px; }
    @media screen and (max-width: 1024px) {
      #header_global.home .logo {
        font-size: 50px; } }
    @media screen and (max-width: 960px) {
      #header_global.home .logo {
        font-size: 40px; } }
    @media screen and (max-width: 768px) {
      #header_global.home .logo {
        font-size: 20px;
        padding: .4em 3%;
        display: inline-block; } }
  #header_global .header_inner {
    display: none;
    padding: 0 5%;
    height: 70px;
    position: relative; }
  #header_global .main_logo {
    padding-left: 5%;
    padding-top: 2em;
    z-index: 99; }
  #header_global .logo {
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    text-rendering: auto;
    color: #fff;
    font-size: 46px;
    white-space: nowrap; }
    @media screen and (max-width: 660px) {
      #header_global .logo {
        font-size: 30px; } }
    #header_global .logo:hover {
      letter-spacing: 0.05em;
      transition: all 0.3s linear; }

.gl_nav_outer {
  display: none; }
  .gl_nav_outer .gl_nav {
    height: 70px;
    text-align: right; }
    .gl_nav_outer .gl_nav li {
      display: inline-block;
      font-size: 14px;
      font-size: 0.875rem;
      white-space: nowrap;
      position: relative;
      color: #fff;
      margin: 0 5px; }
      .gl_nav_outer .gl_nav li.phone .phoneicon {
        font-size: 20px;
        line-height: 70px;
        margin: 0;
        padding: 0 .2em 0 .5em;
        cursor: pointer; }
        .gl_nav_outer .gl_nav li.phone .phoneicon:hover {
          color: #eee; }
      .gl_nav_outer .gl_nav li.phone a {
        display: inline; }
      .gl_nav_outer .gl_nav li.phone .number {
        display: none;
        font-family: 'Roboto', sans-serif;
        font-weight: 200;
        font-size: 24px; }
        .gl_nav_outer .gl_nav li.phone .number:hover {
          border-top: none;
          color: #fff; }
      .gl_nav_outer .gl_nav li a {
        text-decoration: none;
        display: block;
        padding: 0 15px;
        border-top: 2px solid transparent;
        color: #fff;
        line-height: 68px;
        text-align: center; }
        .gl_nav_outer .gl_nav li a:hover {
          border-top: 2px solid #fff;
          color: #eee; }

.gl_nav li.menu_btn {
  cursor: pointer;
  font-size: 21px;
  position: relative;
  overflow: visible;
  color: #fff;
  line-height: 68px;
  padding: 2px 1em 0 1em; }
  .gl_nav li.menu_btn ul#menulist {
    display: none;
    position: absolute;
    background: #fafafa;
    background: rgba(255, 255, 255, 0.2);
    right: 0;
    top: 70px;
    width: 200px;
    z-index: 11; }
    .gl_nav li.menu_btn ul#menulist li {
      display: block;
      height: auto;
      text-align: left;
      font-size: 12px;
      line-height: 1;
      white-space: nowrap;
      margin: 0;
      position: relative; }
      .gl_nav li.menu_btn ul#menulist li a {
        border-top: none;
        line-height: 40px;
        display: block;
        padding: 0 15px; }

/*
 __footer
------------------------------*/
#footer_global {
  border-top: 1px solid #e7e7e7;
  background: #f4f4f4; }
  #footer_global.home {
    border-top: none;
    background: #fff; }
  #footer_global .ft_inner {
    max-width: 1600px;
    margin: 0 auto; }

#copyright {
  font-size: 12px;
  color: #aaa;
  padding: 2em 5%; }
  #copyright p, #copyright small {
    display: block;
    font-size: 100%;
    text-align: right; }
  #copyright a {
    color: #aaa;
    text-decoration: none; }
    #copyright a:hover {
      color: #aaa; }

/*-- contact --*/
#contact_footer .row {
  max-width: 920px; }
#contact_footer .scttl {
  font-weight: bold;
  margin-bottom: 1em; }
  @media screen and (max-width: 660px) {
    #contact_footer .scttl {
      font-size: 12px;
      font-size: 0.75rem;
      text-align: center; } }
#contact_footer .ctflist {
  margin-left: 2em; }
  #contact_footer .ctflist li {
    font-size: 14px;
    font-size: 0.875rem;
    list-style: circle; }
    @media screen and (max-width: 660px) {
      #contact_footer .ctflist li {
        font-size: 10px;
        font-size: 0.625rem; } }
    #contact_footer .ctflist li img {
      vertical-align: middle; }
#contact_footer .paypallogo {
  width: 100px;
  display: block;
  margin-bottom: .5em; }
  @media screen and (max-width: 767px) {
    #contact_footer .paypallogo {
      width: 80px; } }
  #contact_footer .paypallogo img {
    width: 100%; }
#contact_footer .paypall_txt {
  font-size: 14px;
  font-size: 0.875rem; }
  @media screen and (max-width: 979px) {
    #contact_footer .paypall_txt {
      font-size: 12px;
      font-size: 0.75rem; } }
  @media screen and (max-width: 767px) {
    #contact_footer .paypall_txt {
      font-size: 10px;
      font-size: 0.625rem; } }

/*-- mailform --*/
.form_wrapper {
  background: #f5f5f5;
  font-size: 18px;
  color: #24B59F; }
  @media screen and (max-width: 979px) {
    .form_wrapper {
      font-size: 16px; } }
  .form_wrapper .form_fix {
    padding: 0 10%; }
    @media screen and (max-width: 979px) {
      .form_wrapper .form_fix {
        padding: 0; } }

#contactform.sec_padding, #paypalform.sec_padding {
  padding-bottom: 2em; }
#contactform .form_outer, #paypalform .form_outer {
  display: table;
  width: 100%;
  box-sizing: border-box; }
  @media screen and (max-width: 767px) {
    #contactform .form_outer, #paypalform .form_outer {
      display: block; } }
  #contactform .form_outer.singlenote, #paypalform .form_outer.singlenote {
    text-align: center;
    display: table;
    padding: 1em 0 0 0;
    width: 70%;
    margin-left: auto; }
    @media screen and (max-width: 767px) {
      #contactform .form_outer.singlenote, #paypalform .form_outer.singlenote {
        width: 100%;
        margin-left: 0; } }
    #contactform .form_outer.singlenote .inner, #paypalform .form_outer.singlenote .inner {
      font-size: 14px;
      border: 1px solid #eee;
      background: #fafafa; }
      #contactform .form_outer.singlenote .inner .accept, #paypalform .form_outer.singlenote .inner .accept {
        background: #24B59F;
        display: table-cell;
        padding: 10px; }
      #contactform .form_outer.singlenote .inner .note, #paypalform .form_outer.singlenote .inner .note {
        text-align: left;
        display: table-cell;
        font-size: 12px;
        line-height: 1.4;
        vertical-align: middle;
        padding: 0 1em; }
  #contactform .form_outer .tbl_cel, #paypalform .form_outer .tbl_cel {
    display: table-cell;
    padding: .5em 0;
    box-sizing: border-box;
    vertical-align: top; }
    @media screen and (max-width: 767px) {
      #contactform .form_outer .tbl_cel, #paypalform .form_outer .tbl_cel {
        display: block;
        padding: .5em 0 1em 0; } }
  #contactform .form_outer .select_wrapper .wpcf7-form-control-wrap, #paypalform .form_outer .select_wrapper .wpcf7-form-control-wrap {
    position: relative; }
    #contactform .form_outer .select_wrapper .wpcf7-form-control-wrap::before, #paypalform .form_outer .select_wrapper .wpcf7-form-control-wrap::before {
      pointer-events: none;
      position: absolute;
      display: block;
      width: 20px;
      height: 24px;
      right: 10px;
      top: -2px;
      font-family: 'FontAwesome';
      content: '\f078';
      text-rendering: auto;
      color: #24B59F; }
  #contactform .form_outer .heading, #paypalform .form_outer .heading {
    padding-top: 1em;
    width: 30%; }
    @media screen and (max-width: 767px) {
      #contactform .form_outer .heading, #paypalform .form_outer .heading {
        width: 100%;
        padding-bottom: 0; } }
  #contactform .form_outer .kome_txt, #paypalform .form_outer .kome_txt {
    font-size: 10px;
    color: #24B59F;
    vertical-align: super; }

#contactform input[type="text"], #contactform input[type="email"], #contactform input[type="tel"], #contactform textarea, #contactform select, #paypalform input[type="text"], #paypalform input[type="email"], #paypalform input[type="tel"], #paypalform textarea, #paypalform select {
  font-size: 18px;
  background: #fff;
  padding: .8em 1em;
  border: 1px solid #ddd;
  color: #333;
  box-shadow: none;
  border-radius: 0;
  resize: none;
  box-sizing: border-box; }
  @media screen and (max-width: 979px) {
    #contactform input[type="text"], #contactform input[type="email"], #contactform input[type="tel"], #contactform textarea, #contactform select, #paypalform input[type="text"], #paypalform input[type="email"], #paypalform input[type="tel"], #paypalform textarea, #paypalform select {
      font-size: 16px; } }
  #contactform input[type="text"] placeholder, #contactform input[type="email"] placeholder, #contactform input[type="tel"] placeholder, #contactform textarea placeholder, #contactform select placeholder, #paypalform input[type="text"] placeholder, #paypalform input[type="email"] placeholder, #paypalform input[type="tel"] placeholder, #paypalform textarea placeholder, #paypalform select placeholder {
    color: #ccc; }
  #contactform input[type="text"]:focus, #contactform input[type="email"]:focus, #contactform input[type="tel"]:focus, #contactform textarea:focus, #contactform select:focus, #paypalform input[type="text"]:focus, #paypalform input[type="email"]:focus, #paypalform input[type="tel"]:focus, #paypalform textarea:focus, #paypalform select:focus {
    border-color: #24B59F; }
#contactform input[type="submit"], #paypalform input[type="submit"] {
  display: inline-block;
  padding: 1em 2em;
  color: #FFF;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  border: none;
  background: #24B59F;
  border-radius: 0;
  transition: all 0.2s linear;
  opacity: 1;
  box-sizing: border-box; }
  #contactform input[type="submit"]:hover, #paypalform input[type="submit"]:hover {
    background: #20a08c; }
  #contactform input[type="submit"].mf_submit:disabled, #paypalform input[type="submit"].mf_submit:disabled {
    opacity: 0.4;
    background: #ccc; }
#contactform input[type=checkbox].mf_acc, #paypalform input[type=checkbox].mf_acc {
  width: 30px;
  height: 30px;
  vertical-align: middle; }
#contactform select, #paypalform select {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  text-indent: 0.01px;
  text-overflow: ''; }
  #contactform select::-ms-expand, #paypalform select::-ms-expand {
    display: none; }
#contactform .imeoff, #paypalform .imeoff {
  ime-mode: inactive; }
#contactform .imeon, #paypalform .imeon {
  ime-mode: active; }
#contactform .mf_mid, #paypalform .mf_mid {
  width: 100%; }
#contactform .mf_capt, #paypalform .mf_capt {
  width: 50%; }
#contactform .capimg, #paypalform .capimg {
  vertical-align: top;
  padding: .7em .5em;
  background: #24B59F; }
#contactform .comment, #paypalform .comment {
  font-size: 12px; }
#contactform .mf_textarea_tbl, #paypalform .mf_textarea_tbl {
  width: 100%;
  font-size: 14px; }
#contactform .smp, #paypalform .smp {
  margin: 2em auto 0 auto;
  text-align: center; }

/* overwrite wpcf7 */
div.wpcf7 {
  position: relative; }

div.wpcf7 img.ajax-loader {
  display: block;
  margin: .5em auto 0 auto !important; }

div.wpcf7-response-output {
  background: none;
  border: none;
  color: #000;
  text-align: center;
  width: 100%;
  margin: -2em 0 0 0 !important;
  padding: 0 1em 1em 1em !important;
  color: red;
  background: #F5F5F5;
  box-sizing: border-box; }
  @media screen and (max-width: 600px) {
    div.wpcf7-response-output {
      font-size: 12px; } }

div.wpcf7-mail-sent-ok, div.wpcf7-mail-sent-ng, div.wpcf7-spam-blocked, div.wpcf7-validation-errors {
  border: none !important; }

div.wpcf7-mail-sent-ok {
  color: #24B59F !important; }

.wpcf7-not-valid {
  border-color: #F4D03F !important;
  border-width: 2px !important; }

span.wpcf7-not-valid-tip {
  font-size: 12px !important;
  background: transparent !important;
  border: none !important;
  color: red !important;
  box-sizing: border-box !important;
  padding: 0 10px !important; }

/*-----------------------------------------------
 __page_contents
-----------------------------------------------*/
/*----- search form-------*/
.search {
  padding: 20px; }
  .search input[type="text"] {
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 255, 0.4);
    border-radius: 0;
    font-size: 16px;
    line-height: 1;
    padding: .5em; }
  .search input[type="submit"] {
    padding: .5em;
    font-size: 16px;
    color: #FFF;
    border: 1px solid #00A0E9;
    background-color: #00A0E9;
    background-image: none;
    box-shadow: none;
    border-radius: 0; }
    .search input[type="submit"]:hover, .search input[type="submit"]:active {
      color: #FFF;
      background-color: #008ed0;
      background-image: none; }

/*----- poricy -------*/
#policy .chapter {
  margin-top: 3em; }
  #policy .chapter .inner {
    padding: 2em; }
  #policy .chapter .chpttl {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1em; }
  #policy .chapter .lstttl {
    font-weight: bold;
    margin: 1em 0 .5em 0; }
#policy ul.list_contents {
  padding: 0;
  margin: .5em 0 .5em 2em; }
  #policy ul.list_contents li {
    padding: 0 0 1em 0;
    margin: 0;
    list-style: circle; }

/*-----------------------------------------------
 __home_contents
-----------------------------------------------*/
.home_section.first_view {
  position: relative;
  height: 600px; }
  .home_section.first_view .row {
    z-index: 2; }
  @media screen and (max-width: 960px) {
    .home_section.first_view {
      height: 500px; } }
  @media screen and (max-width: 500px) {
    .home_section.first_view .wrapper {
      height: 500px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 1; } }
  .home_section.first_view .catch_wrap {
    padding-top: 5%; }
    .home_section.first_view .catch_wrap .main_copy {
      text-align: center;
      color: #fff;
      font-size: 40px;
      padding-bottom: .5em; }
      .home_section.first_view .catch_wrap .main_copy br {
        display: none; }
      @media screen and (max-width: 768px) {
        .home_section.first_view .catch_wrap .main_copy {
          font-size: 36px; } }
      @media screen and (max-width: 660px) {
        .home_section.first_view .catch_wrap .main_copy {
          font-size: 30px; } }
      @media screen and (max-width: 500px) {
        .home_section.first_view .catch_wrap .main_copy br {
          display: block; } }
    .home_section.first_view .catch_wrap .readcopy {
      text-align: center;
      color: #fff; }
      @media screen and (max-width: 840px) {
        .home_section.first_view .catch_wrap .readcopy {
          text-align: left; }
          .home_section.first_view .catch_wrap .readcopy p {
            display: inline; } }
.home_section .contact_btn_wrap {
  margin-top: 80px; }
.home_section .contact_btn {
  text-decoration: none;
  height: 60px;
  border: 1px solid #fff;
  width: 200px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin: 0 auto;
  line-height: 60px;
  display: block;
  background: rgba(90, 119, 127, 0.3); }
  .home_section .contact_btn:hover {
    background: #24B59F;
    background: rgba(36, 181, 159, 0.7);
    border-color: #24B59F;
    border-color: rgba(36, 181, 159, 0.7);
    transition: all 0.2s linear;
    height: 200px;
    line-height: 200px;
    margin-top: -68px;
    border-radius: 101px; }
.home_section .sec_ttl {
  font-size: 36px;
  font-size: 2.25rem;
  margin-bottom: 1em;
  text-align: center; }
  @media screen and (max-width: 768px) {
    .home_section .sec_ttl {
      font-size: 30px;
      font-size: 1.875rem; } }
  @media screen and (max-width: 660px) {
    .home_section .sec_ttl {
      font-size: 22px;
      font-size: 1.375rem; } }
  .home_section .sec_ttl.mid {
    font-size: 26px;
    font-size: 1.625rem; }
  .home_section .sec_ttl.tal {
    text-align: left; }
  .home_section .sec_ttl.rob {
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    text-rendering: auto;
    text-decoration: none; }
    .home_section .sec_ttl.rob .em {
      font-weight: 300; }
.home_section.wecando .support {
  background: rgba(218, 227, 228, 0.98); }
  @media screen and (max-width: 768px) {
    .home_section.wecando .support {
      padding-bottom: 5%; } }
  .home_section.wecando .support .support_inner {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 8% 5% 5% 5%;
    box-sizing: border-box;
    background: url(../images/base/wcd_bg.png) 0 0 no-repeat;
    background-position: center right;
    background-size: 50%; }
    @media screen and (min-width: 1600px) {
      .home_section.wecando .support .support_inner {
        background-size: 800px; } }
    @media screen and (max-width: 960px) {
      .home_section.wecando .support .support_inner {
        background-size: 40%; } }
    @media screen and (max-width: 768px) {
      .home_section.wecando .support .support_inner {
        background-position: bottom center;
        background-size: 70%; } }
    @media screen and (max-width: 660px) {
      .home_section.wecando .support .support_inner {
        background-size: 90%; } }
    .home_section.wecando .support .support_inner .summary {
      width: 50%;
      box-sizing: border-box; }
      @media screen and (max-width: 960px) {
        .home_section.wecando .support .support_inner .summary {
          width: 60%; } }
      @media screen and (max-width: 768px) {
        .home_section.wecando .support .support_inner .summary {
          padding-bottom: 40%;
          width: 100%; } }
      @media screen and (max-width: 660px) {
        .home_section.wecando .support .support_inner .summary {
          color: #555;
          padding-bottom: 45%; } }
      .home_section.wecando .support .support_inner .summary p {
        font-size: 15px;
        font-size: 0.9375rem;
        margin-bottom: 1em;
        padding-right: 5%; }
        @media screen and (max-width: 768px) {
          .home_section.wecando .support .support_inner .summary p {
            padding: 0; } }
        @media screen and (max-width: 660px) {
          .home_section.wecando .support .support_inner .summary p {
            font-size: 14px;
            font-size: 0.875rem; } }
.home_section.wecando .solution {
  background: #e8e8e8; }
  .home_section.wecando .solution .solution_list {
    background: #f0f0f0;
    width: 100%;
    max-width: 1600px;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0 auto; }
    .home_section.wecando .solution .solution_list .list {
      display: block;
      float: left;
      width: 33.3%;
      background: #f5f5f5;
      box-sizing: border-box;
      padding: 3% 2%;
      height: 300px; }
      .home_section.wecando .solution .solution_list .list:nth-child(2n) {
        background: #f0f0f0; }
      @media screen and (max-width: 960px) {
        .home_section.wecando .solution .solution_list .list {
          width: 50%;
          height: 240px; }
          .home_section.wecando .solution .solution_list .list:nth-child(2n) {
            background: #f5f5f5; }
          .home_section.wecando .solution .solution_list .list:nth-child(2) {
            background: #f0f0f0; }
          .home_section.wecando .solution .solution_list .list:nth-child(3n) {
            background: #f0f0f0; } }
      @media screen and (max-width: 800px) {
        .home_section.wecando .solution .solution_list .list {
          height: 260px; } }
      @media screen and (max-width: 660px) {
        .home_section.wecando .solution .solution_list .list {
          height: 200px; } }
      @media screen and (max-width: 580px) {
        .home_section.wecando .solution .solution_list .list {
          float: none;
          width: 100%;
          padding: 0;
          display: table;
          height: 120px;
          background: #f5f5f5; }
          .home_section.wecando .solution .solution_list .list:nth-child(2n) {
            background: #f0f0f0; }
          .home_section.wecando .solution .solution_list .list:nth-child(2) {
            background: transparent; }
          .home_section.wecando .solution .solution_list .list:nth-child(3n) {
            background: transparent; }
          .home_section.wecando .solution .solution_list .list:nth-child(3) {
            background: #f5f5f5; } }
      .home_section.wecando .solution .solution_list .list .icon_wrap {
        text-align: center;
        box-sizing: border-box; }
        @media screen and (max-width: 580px) {
          .home_section.wecando .solution .solution_list .list .icon_wrap {
            display: table-cell;
            width: 80px;
            vertical-align: middle; }
            .home_section.wecando .solution .solution_list .list .icon_wrap.production {
              background: #00A0E9; }
            .home_section.wecando .solution .solution_list .list .icon_wrap.ecsite {
              background: #76D1DE; }
            .home_section.wecando .solution .solution_list .list .icon_wrap.mobile {
              background: #F63378; }
            .home_section.wecando .solution .solution_list .list .icon_wrap.social {
              background: #C1D54C; }
            .home_section.wecando .solution .solution_list .list .icon_wrap.maintenance {
              background: #F36C35; }
            .home_section.wecando .solution .solution_list .list .icon_wrap.graphic {
              background: #24B59F; } }
        .home_section.wecando .solution .solution_list .list .icon_wrap .icon {
          font-size: 58px;
          color: #fff; }
          @media screen and (max-width: 660px) {
            .home_section.wecando .solution .solution_list .list .icon_wrap .icon {
              font-size: 40px; } }
          @media screen and (max-width: 580px) {
            .home_section.wecando .solution .solution_list .list .icon_wrap .icon {
              color: #fff !important;
              text-align: center;
              font-size: 30px; } }
          .home_section.wecando .solution .solution_list .list .icon_wrap .icon.production {
            color: #00A0E9; }
          .home_section.wecando .solution .solution_list .list .icon_wrap .icon.ecsite {
            color: #76D1DE; }
          .home_section.wecando .solution .solution_list .list .icon_wrap .icon.mobile {
            color: #F63378; }
          .home_section.wecando .solution .solution_list .list .icon_wrap .icon.social {
            color: #C1D54C; }
          .home_section.wecando .solution .solution_list .list .icon_wrap .icon.maintenance {
            color: #F36C35; }
          .home_section.wecando .solution .solution_list .list .icon_wrap .icon.graphic {
            color: #24B59F; }
      .home_section.wecando .solution .solution_list .list .contents_wrap {
        padding: 5%;
        box-sizing: border-box; }
        @media screen and (max-width: 580px) {
          .home_section.wecando .solution .solution_list .list .contents_wrap {
            display: table-cell; } }
      .home_section.wecando .solution .solution_list .list .support_ttl {
        font-size: 15px;
        font-size: 0.9375rem;
        line-height: 1.55;
        text-align: center;
        margin-bottom: 1em;
        font-weight: bold;
        color: #555; }
        @media screen and (max-width: 1279px) {
          .home_section.wecando .solution .solution_list .list .support_ttl {
            text-align: left; } }
        @media screen and (max-width: 660px) {
          .home_section.wecando .solution .solution_list .list .support_ttl {
            font-size: 12px;
            font-size: 0.75rem; } }
      .home_section.wecando .solution .solution_list .list .summary {
        font-size: 12px;
        font-size: 0.75rem; }
        @media screen and (max-width: 660px) {
          .home_section.wecando .solution .solution_list .list .summary {
            font-size: 10px;
            font-size: 0.625rem; } }
.home_section.price {
  background: #dae3e4;
  padding: 5% 0 0 0; }
  .home_section.price .summary {
    text-align: center; }
    @media screen and (max-width: 659px) {
      .home_section.price .summary {
        text-align: left;
        font-size: 13px;
        font-size: 0.8125rem;
        color: #555; } }
  .home_section.price .pricelist_wrap {
    max-width: 1600px;
    margin: 0 auto; }
  .home_section.price .listttl_wraper {
    padding: 3em 0 0 0; }
    @media screen and (max-width: 740px) {
      .home_section.price .listttl_wraper {
        padding: 2em 0 0 0; } }
    .home_section.price .listttl_wraper .list_tl {
      display: inline-block;
      width: 25%;
      box-sizing: border-box;
      text-align: center;
      vertical-align: top; }
      .home_section.price .listttl_wraper .list_tl.bdr {
        border-right: 1px solid #cbd8da; }
      @media screen and (max-width: 740px) {
        .home_section.price .listttl_wraper .list_tl {
          width: 50%; }
          .home_section.price .listttl_wraper .list_tl:first-child {
            border-bottom: 1px solid #cbd8da; }
          .home_section.price .listttl_wraper .list_tl:nth-child(2) {
            border-bottom: 1px solid #cbd8da; } }
      @media screen and (max-width: 660px) {
        .home_section.price .listttl_wraper .list_tl {
          border: none !important; }
          .home_section.price .listttl_wraper .list_tl.production {
            background: #00A0E9; }
            .home_section.price .listttl_wraper .list_tl.production .inner:hover {
              background: #03b0ff; }
          .home_section.price .listttl_wraper .list_tl.mobile {
            background: #F63378; }
            .home_section.price .listttl_wraper .list_tl.mobile .inner:hover {
              background: #f74b88; }
          .home_section.price .listttl_wraper .list_tl.graphic {
            background: #24B59F; }
            .home_section.price .listttl_wraper .list_tl.graphic .inner:hover {
              background: #28cab2; }
          .home_section.price .listttl_wraper .list_tl.maintenance {
            background: #F36C35; }
            .home_section.price .listttl_wraper .list_tl.maintenance .inner:hover {
              background: #f47e4d; } }
      .home_section.price .listttl_wraper .list_tl .inner {
        display: block;
        text-decoration: none;
        padding: 15% 10%;
        box-sizing: border-box;
        height: 260px; }
        @media screen and (max-width: 740px) {
          .home_section.price .listttl_wraper .list_tl .inner {
            padding: 8% 10%; } }
        @media screen and (max-width: 660px) {
          .home_section.price .listttl_wraper .list_tl .inner {
            padding: 2em 10% 2% 10%;
            height: 200px; } }
        .home_section.price .listttl_wraper .list_tl .inner:hover {
          background: #00A0E9; }
          .home_section.price .listttl_wraper .list_tl .inner:hover .icon {
            color: #fff !important; }
          .home_section.price .listttl_wraper .list_tl .inner:hover .list_nav_ttl {
            color: #fff; }
          .home_section.price .listttl_wraper .list_tl .inner:hover .note {
            color: #fff; }
          .home_section.price .listttl_wraper .list_tl .inner:hover .downicon {
            color: #fff; }
        .home_section.price .listttl_wraper .list_tl .inner .list_nav_ttl {
          font-size: 15px;
          font-size: 0.9375rem;
          font-weight: bold;
          white-space: nowrap;
          padding-top: 2em;
          display: block;
          color: #555; }
          @media screen and (max-width: 960px) {
            .home_section.price .listttl_wraper .list_tl .inner .list_nav_ttl {
              font-size: 14px;
              font-size: 0.875rem; } }
          @media screen and (max-width: 768px) {
            .home_section.price .listttl_wraper .list_tl .inner .list_nav_ttl {
              font-size: 13px;
              font-size: 0.8125rem;
              white-space: pre-wrap; } }
          @media screen and (max-width: 660px) {
            .home_section.price .listttl_wraper .list_tl .inner .list_nav_ttl {
              padding-top: 1em;
              font-size: 12px;
              font-size: 0.75rem;
              color: #fff; } }
        .home_section.price .listttl_wraper .list_tl .inner .note {
          font-size: 12px;
          font-size: 0.75rem;
          text-align: left;
          color: #000;
          padding: 1em 0 0 0; }
          @media screen and (max-width: 660px) {
            .home_section.price .listttl_wraper .list_tl .inner .note {
              font-size: 10px;
              font-size: 0.625rem;
              color: #fff; } }
        .home_section.price .listttl_wraper .list_tl .inner .icon {
          font-size: 60px;
          display: block;
          color: #00A0E9; }
          @media screen and (max-width: 660px) {
            .home_section.price .listttl_wraper .list_tl .inner .icon {
              font-size: 40px;
              display: inline;
              color: #fff !important; } }
          .home_section.price .listttl_wraper .list_tl .inner .icon.downicon {
            font-size: 20px; }
          .home_section.price .listttl_wraper .list_tl .inner .icon.production {
            color: #00A0E9; }
          .home_section.price .listttl_wraper .list_tl .inner .icon.mobile {
            color: #F63378; }
          .home_section.price .listttl_wraper .list_tl .inner .icon.maintenance {
            color: #F36C35; }
          .home_section.price .listttl_wraper .list_tl .inner .icon.graphic {
            color: #24B59F; }
.home_section.infomation {
  display: table;
  width: 100%;
  box-sizing: border-box;
  max-width: 1600px;
  margin: 0 auto; }
  @media screen and (max-width: 659px) {
    .home_section.infomation {
      display: block; } }
  .home_section.infomation .about {
    display: table-cell;
    width: 50%;
    background: #eee; }
    @media screen and (max-width: 659px) {
      .home_section.infomation .about {
        width: 100%;
        display: block; } }
    .home_section.infomation .about .inner {
      padding: 5%; }
    .home_section.infomation .about .company_info {
      width: 100%;
      box-sizing: border-box;
      font-size: 12px;
      font-size: 0.75rem;
      padding: 0 5%;
      overflow: hidden; }
      @media screen and (max-width: 659px) {
        .home_section.infomation .about .company_info {
          padding: 0; } }
      .home_section.infomation .about .company_info dt {
        float: left;
        clear: left;
        width: 20%;
        box-sizing: border-box;
        padding: 1% 2%;
        color: #24B59F;
        text-align: right; }
      .home_section.infomation .about .company_info dd {
        float: left;
        width: 80%;
        box-sizing: border-box;
        padding: 1% 2%; }
        .home_section.infomation .about .company_info dd.small {
          font-size: 10px;
          font-size: 0.625rem; }
      .home_section.infomation .about .company_info .callto {
        text-decoration: none;
        display: inline-block;
        border: 1px solid #24B59F;
        color: #24B59F;
        padding: .5em 1em;
        line-height: 1em;
        border-radius: 1em; }
  .home_section.infomation .contact {
    display: table-cell;
    empty-cells: 50%;
    background: #24B59F;
    color: #fff; }
    @media screen and (max-width: 659px) {
      .home_section.infomation .contact {
        width: 100%;
        display: block; } }
    .home_section.infomation .contact .inner {
      padding: 5%; }
    .home_section.infomation .contact .summary {
      text-align: center;
      font-size: 14px;
      font-size: 0.875rem; }
      @media screen and (max-width: 659px) {
        .home_section.infomation .contact .summary {
          text-align: left; }
          .home_section.infomation .contact .summary p {
            display: inline; } }
    .home_section.infomation .contact .cbtn_wrap {
      text-align: center;
      padding-top: 2em; }
      .home_section.infomation .contact .cbtn_wrap .c_btn {
        border: 1px solid #fff;
        display: inline-block;
        text-decoration: none;
        color: #fff;
        padding: 1em 2em; }
        .home_section.infomation .contact .cbtn_wrap .c_btn:hover {
          background: #19c0a7;
          transition: all 0.2s linear; }
    .home_section.infomation .contact .icon_wrap {
      padding-top: 2em;
      text-align: center; }
      .home_section.infomation .contact .icon_wrap img {
        width: 160px;
        margin-left: 15%; }

.home_price_detail .list_ttl {
  font-size: 24px;
  font-size: 1.5rem;
  margin-bottom: 2em; }
  @media screen and (max-width: 767px) {
    .home_price_detail .list_ttl {
      font-size: 18px;
      font-size: 1.125rem; } }
.home_price_detail .pricelist {
  font-size: 12px; }
  @media screen and (max-width: 660px) {
    .home_price_detail .pricelist {
      display: block; }
      .home_price_detail .pricelist tr {
        display: block;
        width: 100%;
        box-sizing: border-box; }
      .home_price_detail .pricelist td {
        display: block; } }
  .home_price_detail .pricelist .item_name {
    width: 25%;
    border-bottom: 1px solid #bccdcf;
    padding: 1em;
    color: #00A0E9; }
    @media screen and (max-width: 660px) {
      .home_price_detail .pricelist .item_name {
        width: 100%;
        border: none;
        box-sizing: border-box;
        padding: 1em 1em .2em 1em; }
        .home_price_detail .pricelist .item_name br {
          display: none; } }
  .home_price_detail .pricelist .item_price {
    width: 20%;
    border-bottom: 1px solid #bccdcf;
    padding: 1em; }
    @media screen and (max-width: 660px) {
      .home_price_detail .pricelist .item_price {
        width: 100%;
        border: none;
        box-sizing: border-box;
        padding: .2em 1em .5em 1em; } }
  .home_price_detail .pricelist .item_de {
    width: 55%;
    border-bottom: 1px solid #bccdcf;
    padding: 1em; }
    @media screen and (max-width: 767px) {
      .home_price_detail .pricelist .item_de {
        font-size: 10px; } }
    @media screen and (max-width: 660px) {
      .home_price_detail .pricelist .item_de {
        width: 100%;
        box-sizing: border-box;
        padding: 0 .5em 1em 1em; } }
.home_price_detail .plan_wrap {
  background: #eff3f3;
  overflow: hidden; }
.home_price_detail .support_plan {
  font-size: 12px;
  width: 50%;
  box-sizing: border-box;
  float: left; }
  .home_price_detail .support_plan td, .home_price_detail .support_plan th {
    border: none; }
  @media screen and (max-width: 660px) {
    .home_price_detail .support_plan {
      width: 100%; } }
  .home_price_detail .support_plan .plan_name {
    width: 50%;
    padding: 1em;
    font-weight: normal;
    background: #e9eeef;
    text-align: center;
    box-sizing: border-box; }
    @media screen and (max-width: 860px) {
      .home_price_detail .support_plan .plan_name {
        font-size: 11px;
        padding: 1em .5em; } }
    @media screen and (max-width: 767px) {
      .home_price_detail .support_plan .plan_name {
        font-size: 10px; } }
  .home_price_detail .support_plan .plan_price {
    padding: 2em 0;
    text-align: center;
    color: #00A0E9;
    background: #fafafa; }
    .home_price_detail .support_plan .plan_price .per_price {
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      text-rendering: auto;
      text-decoration: none;
      font-size: 38px;
      line-height: 1; }
      @media screen and (max-width: 767px) {
        .home_price_detail .support_plan .plan_price .per_price {
          font-size: 30px; } }
    .home_price_detail .support_plan .plan_price .per_month {
      padding-top: .5em; }
      @media screen and (max-width: 767px) {
        .home_price_detail .support_plan .plan_price .per_month {
          font-size: 10px; } }
  .home_price_detail .support_plan .plan_name_en {
    background: #00A0E9;
    color: #fff; }
    .home_price_detail .support_plan .plan_name_en.bdr {
      border-color: #008ed0; }
    .home_price_detail .support_plan .plan_name_en .plan_ttl {
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      text-rendering: auto;
      text-decoration: none;
      font-size: 24px;
      line-height: 1;
      padding: .5em 0; }
      @media screen and (max-width: 767px) {
        .home_price_detail .support_plan .plan_name_en .plan_ttl {
          font-size: 20px; } }
  .home_price_detail .support_plan .bdr {
    border-left: 1px solid #dae3e4; }
  .home_price_detail .support_plan .plan_detail {
    background: #eff3f3;
    vertical-align: top; }
  .home_price_detail .support_plan .plan_detail_menu li {
    border-bottom: 1px solid #dae3e4;
    padding: .5em 1em;
    font-size: 11px;
    color: #555;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .home_price_detail .support_plan .plan_detail_menu li {
        font-size: 10px;
        padding: .5em; } }

