@charset "utf-8";
@import "_base.scss";
@import "_mixin.scss";

/*-----------------------------------------------
 __header
-----------------------------------------------*/
#header_global {
	max-width: 1600px;
	margin: 0 auto;
	&.home {
		@include media(768px) {
			.main_logo {
				padding: 0;
				z-index: 99;
				background: rgba(0,0,0,0.03);
				@include media(500px) {
					background: rgba(0,0,0,0.2);
				}
			}
		}
		.logo {
			font-size: 60px;
			@include media(1024px) {
				font-size: 50px;
			}
			@include media(960px) {
				font-size: 40px;
			}
			@include media(768px) {
				font-size: 20px;
				padding: .4em 3%;
				display: inline-block;
			}
		}
	}
	.header_inner {
		display: none;
		padding: 0 5%;
		height: 70px;
		position: relative;
	}
	.main_logo {
		padding-left: 5%;
		padding-top: 2em;
		z-index: 99;
	}
	.logo {
		text-decoration: none;
		font-family: 'Roboto', sans-serif;
		font-weight: 300;
		text-rendering: auto;
		color: #fff;
		font-size: 46px;
		white-space: nowrap;
		@include media(660px) {
			font-size: 30px;
		}
		&:hover {
			letter-spacing: 0.05em;
			transition: all 0.3s linear;
		}
	}
}
.gl_nav_outer {
	display: none;
	.gl_nav {
		height: 70px;
		text-align: right;
		li {
			display: inline-block;
			@include font-size(14);
			white-space: nowrap;
			position: relative;
			color: #fff;
			margin: 0 5px;
			&.phone {
				.phoneicon {
					font-size: 20px;
					line-height: 70px;
					margin: 0;
					padding: 0 .2em 0 .5em;
					cursor: pointer;
					&:hover {
						color: #eee;
					}
				}
				a {
					display: inline;
				}
				.number {
					display: none;
					font-family: 'Roboto', sans-serif;
					font-weight: 200;
					font-size: 24px;
					&:hover {
						border-top: none;
						color: #fff;
					}
				}
			}
			a {
				text-decoration: none;
				display: block;
				padding: 0 15px;
				border-top: 2px solid transparent;
				color: #fff;
				line-height: 68px;
				text-align: center;
				&:hover {
					border-top: 2px solid #fff;
					color: #eee;
					}
				}
		}
	}
}
.gl_nav li.menu_btn {
	cursor: pointer;
	font-size: 21px;
	position: relative;
	overflow: visible;
	color: #fff;
	line-height: 68px;
	padding: 2px 1em 0 1em;
	ul#menulist {
		display: none;
		position: absolute;
		background: #fafafa;
		background: rgba(255,255,255,.2);
		right: 0;
		top: 70px;
		width: 200px;
		z-index: 11;
		li{
			display: block;
			height: auto;
			text-align: left;
			font-size: 12px;
			line-height: 1;
			white-space: nowrap;
			margin: 0;
			position: relative;
			a {
				border-top: none;
				line-height: 40px;
				display: block;
				padding: 0 15px;
			}
		}
	}
}
