@charset "utf-8";
@import "_base.scss";
@import "_mixin.scss";

@include base-font-size(16);

body {
	line-height: 1.75;
	@include font-size(16);
	@include media(979px) {
		@include font-size(14);
	}
}

@import "_loader.scss" ;


/*-----------------------------------------------
 __layout
-----------------------------------------------*/
#wrap {
	min-height: 100%;
	max-height: 100%;
	position: relative;
	background: #29a4d1; /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMTAwJSIgeDI9IjEwMCUiIHkyPSIwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMjlhNGQxIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMjglIiBzdG9wLWNvbG9yPSIjMjRiNTlmIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNDQlIiBzdG9wLWNvbG9yPSIjMjRiNTlmIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iODklIiBzdG9wLWNvbG9yPSIjNjBhYmU1IiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
	background: -moz-linear-gradient(45deg,  #29a4d1 0%, #24b59f 28%, #24b59f 44%, #60abe5 89%); /* FF3.6+ */
	background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,#29a4d1), color-stop(28%,#24b59f), color-stop(44%,#24b59f), color-stop(89%,#60abe5)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(45deg,  #29a4d1 0%,#24b59f 28%,#24b59f 44%,#60abe5 89%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(45deg,  #29a4d1 0%,#24b59f 28%,#24b59f 44%,#60abe5 89%); /* Opera 11.10+ */
	background: -ms-linear-gradient(45deg,  #29a4d1 0%,#24b59f 28%,#24b59f 44%,#60abe5 89%); /* IE10+ */
	background: linear-gradient(45deg,  #29a4d1 0%,#24b59f 28%,#24b59f 44%,#60abe5 89%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#29a4d1', endColorstr='#60abe5',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */
	background-size: 100% 500px;
	background-repeat: no-repeat;
	&.home {
		background: #9FADAD url(#{$img_path}/base/1st_view_bg_001.jpg) no-repeat;
		background-attachment: fixed;
		background-size: 1600px;
		background-position: 50% -60px;
		@include media(1600px) {
			background-size: 1600px;
		}
		@include media(960px) {
			background-size: 1400px;
		}
		@include media(768px) {
			background-size: 1200px;
		}
		@include media(500px) {
			background-size: 1000px;
		}
	}
	&.android {
		background-attachment: scroll;
	}
	&.invoice {
		background: #60abe5;
	}
}
html.ie9 #wrap.gradient {
	filter: none;
}

#contents {
	width: 100%;
}
.row {
	max-width: 1140px;
	width: 100%;
	margin: 0 auto;
	text-align: left;
	position: relative;
	padding: 0 5%;
	box-sizing: border-box;
	&.txt_ac {
		text-align: center;
	}
}
.dnon {display: none;}
#section_header {
	&.common {
		padding: 5em 0;
		@include media(660px) {
			padding-bottom: 3em;
		}
	}
	.description {
		padding: 3em 0;
		line-height: 1.7;
	}
	.tcf {
		color: #fff;
	}
	.tac {
		text-align: center;
	}
}
.title {
	line-height: 1.2;
	&.sch_ttl {
		@include font-size(46);
		@include media(979px) {
			@include font-size(38);
		}
		@include media(660px) {
			@include font-size(30);
		}
		&.mid {
			@include font-size(30);
			@include media(979px) {
				@include font-size(26);
			}
			@include media(660px) {
				@include font-size(22);
			}
		}
		&.invoice {
			@include font-size(30);
			@include media(979px) {
				@include font-size(26);
			}
			@include media(660px) {
				@include font-size(22);
			}
		}
	}
}

#section_contents {
	background: #fff;
	.sec_padding {
		padding-top: 5em;
		padding-bottom: 5em;
		@include media(767px) {
			padding-top: 3em;
			padding-bottom: 3em;
		}
	}
	.intro {
		p {
			margin-bottom: .8em;
		}
		.invoice_intro {
			font-size: 14px;
			@include media(767px) {
				font-size: 12px;
			}
			p {
				margin-bottom: .5em;
			}
		}
	}
	.tac {
		text-align: center;
		@include media(979px) {
			text-align: left;
		}
	}
}

.column_wrapper {
	box-sizing: border-box;
	width: 100%;
	&.di_tbl {
		display: table;
	}
	&.tbl_lay_fix {
		table-layout: fixed;
	}
	&.tal {
		text-align: left;
	}
	&.tac {
		text-align: center;
	}
	&.padt {
		padding-top: 5em;
	}
	.column {
		box-sizing: border-box;
		&.di_tblc {
			display: table-cell;
		}
		&.di_inb {
			display: inline-block;
			&.box3 {
				width: 33.3%;
			}
		}
		&.tal {
			text-align: left;
		}
		&.tac {
			text-align: center;
		}
		&.pd {
			padding: 1em 2em;
		}
	}
	.btn {
		text-decoration: none;
		display: block;
		border: 2px solid $point-color;
		padding: 1em;
		color: $point-color;
	}
}



@import "_develop.scss";
@import "_header.scss";
@import "_bread.scss";
@import "_footer.scss";
@import "_contact.scss";
@import "_post.scss" ;
@import "_page.scss";
@import "_home.scss";
