@charset "utf-8";
@import "_base.scss";
@import "_mixin.scss";

/*
 __footer
------------------------------*/

#footer_global {
	border-top: 1px solid darken(#f4f4f4,5%);
	background: #f4f4f4;
	&.home {
		border-top: none;
		background: #fff;
	}
	.ft_inner {
		max-width: 1600px;
		margin: 0 auto;
	}
}
#copyright {
	font-size: 12px;
	color: #aaa;
	padding: 2em 5%;
	p,small {
		display: block;
		font-size: 100%;
		text-align: right;
	}
	a {
		color: #aaa;
		text-decoration: none;
		&:hover {
			color: #aaa;
		}
	}
}
