@charset "utf-8";
@import "_base.scss";
@import "_mixin.scss";

/*-----------------------------------------------
 __home_contents
-----------------------------------------------*/

.home_section {
	&.first_view {
		position: relative;
		height: 600px;
		.row {
			z-index: 2;
		}
		@include media(960px) {
			height: 500px;
		}
		@include media(500px) {
			.wrapper {
				height: 500px;
				width: 100%;
				position: absolute;
				left: 0;
				top: 0;
				background: rgba(0,0,0,0.3);
				z-index: 1;
			}
		}
		.catch_wrap {
			padding-top: 5%;
			.main_copy {
				text-align: center;
				color: #fff;
				font-size:40px;
				padding-bottom: .5em;
				br {display: none;}
				@include media(768px) {
					font-size:36px;
				}
				@include media(660px) {
					font-size:30px;
				}
				@include media(500px) {
					br {display: block;}
				}
			}
			.readcopy {
				text-align: center;
				color: #fff;
				@include media(840px) {
					text-align: left;
					p {
						display: inline;
					}
				}
			}
		}
	}
	.contact_btn_wrap {
		margin-top: 80px;
	}
	.contact_btn {
		text-decoration: none;
		height: 60px;
		border: 1px solid #fff;
		width: 200px;
		color: #fff;
		font-size: 18px;
		text-align: center;
		margin: 0 auto;
		line-height: 60px;
		display: block;
		background: rgba(#5A777F,0.3);
		&:hover {
			background: $point-color;
			background: rgba($point-color,0.7);
			border-color: $point-color;
			border-color: rgba($point-color,0.7);
			transition: all 0.2s linear;
			height: 200px;
			line-height: 200px;
			margin-top: -68px;
			border-radius: 101px;
		}
	}
	.sec_ttl {
		@include font-size(36);
		margin-bottom: 1em;
		text-align: center;
		@include media(768px) {
			@include font-size(30);
		}
		@include media(660px) {
			@include font-size(22);
		}
		&.mid {
			@include font-size(26);
		}
		&.tal {
			text-align: left;
		}
		&.rob {
			font-family: 'Roboto', sans-serif;
			font-weight: 100;
			text-rendering: auto;
			text-decoration: none;
			.em {
				font-weight: 300;
			}
		}
	}
	&.wecando {
		.support {
			background: rgba(lighten(#90ACAF,25%),0.98);
			@include media(768px) {
				padding-bottom: 5%;
			}
			.support_inner {
				width: 100%;
				max-width: 1600px;
				margin: 0 auto;
				padding: 8% 5% 5% 5%;
				box-sizing: border-box;
				background: url(../images/base/wcd_bg.png) 0 0 no-repeat;
				background-position: center right;
				background-size: 50%;
				@include min_media(1600px) {
					background-size: 800px;
				}
				@include media(960px) {
					background-size: 40%;
				}
				@include media(768px) {
					background-position: bottom center;
					background-size: 70%;
				}
				@include media(660px) {
					background-size: 90%;
				}
				.summary {
					width: 50%;
					box-sizing: border-box;
					@include media(960px) {
						width: 60%;
					}
					@include media(768px) {
						padding-bottom: 40%;
						width: 100%;
					}
					@include media(660px) {
						color: #555;
						padding-bottom: 45%;
					}
					p {
						@include font-size(15);
						margin-bottom: 1em;
						padding-right: 5%;
						@include media(768px) {
							padding: 0;
						}
						@include media(660px) {
							@include font-size(14);
						}
					}
				}
			}
		}
		.solution {
			background: darken(#f5f5f5,5%);
			.solution_list {
				background: darken(#f5f5f5,2%);
				width: 100%;
				max-width: 1600px;
				box-sizing: border-box;
				overflow: hidden;
				margin: 0 auto;
				.list {
					display: block;
					float: left;
					width:33.3%;
					background: #f5f5f5;
					box-sizing: border-box;
					padding: 3% 2%;
					height: 300px;
					&:nth-child(2n) {
						background: darken(#f5f5f5,2%);
					}
					@include media(960px) {
						width: 50%;
						height: 240px;
						&:nth-child(2n) {
							background: #f5f5f5;
						}
						&:nth-child(2) {
							background: darken(#f5f5f5,2%);
						}
						&:nth-child(3n) {
							background: darken(#f5f5f5,2%);
						}
					}
					@include media(800px) {
						height: 260px;
					}
					@include media(660px) {
						height: 200px;
					}
					@include media(580px) {
						float: none;
						width: 100%;
						padding: 0;
						display: table;
						height: 120px;
						background: #f5f5f5;
						&:nth-child(2n) {
							background: darken(#f5f5f5,2%);
						}
						&:nth-child(2) {
							background: transparent;
						}
						&:nth-child(3n) {
							background: transparent;
						}
						&:nth-child(3) {
							background: #f5f5f5;
						}
					}
					.icon_wrap {
						text-align: center;
						box-sizing: border-box;
						@include media(580px) {
							display: table-cell;
							width: 80px;
							vertical-align: middle;
							&.production {
								background: $base-color;
							}
							&.ecsite {
								background: #76D1DE;
							}
							&.mobile {
								background: #F63378;
							}
							&.social {
								background: #C1D54C;
							}
							&.maintenance {
								background: #F36C35;
							}
							&.graphic {
								background: $point-color;
							}
						}
						.icon {
							font-size: 58px;
							color: #fff;
							@include media(660px) {
								font-size: 40px;
							}
							@include media(580px) {
								color: #fff!important;
								text-align: center;
								font-size: 30px;
							}
							&.production {
								color: $base-color;
							}
							&.ecsite {
								color: #76D1DE;
							}
							&.mobile {
								color: #F63378;
							}
							&.social {
								color: #C1D54C;
							}
							&.maintenance {
								color: #F36C35;
							}
							&.graphic {
								color: $point-color;
							}
						}
					}
					.contents_wrap {
						padding: 5%;
						box-sizing: border-box;
						@include media(580px) {
							display: table-cell;
						}
					}
					.support_ttl {
						@include font-size(15);
						line-height: 1.55;
						text-align: center;
						margin-bottom: 1em;
						font-weight: bold;
						color: #555;
						@include media(1279px) {
							text-align: left;
						}
						@include media(660px) {
							@include font-size(12);
						}
					}
					.summary {
						@include font-size(12);
						@include media(660px) {
							@include font-size(10);
						}
					}
				}
			}
		}
	}
	&.price {
		background: lighten(#90ACAF,25%);
		padding: 5% 0 0 0;
		.summary {
			text-align: center;
			@include media(659px) {
				text-align: left;
				@include font-size(13);
				color: #555;
			}
		}
		.pricelist_wrap {
			max-width: 1600px;
			margin: 0 auto;
		}
		.listttl_wraper {
			padding: 3em 0 0 0;
			@include media(740px) {
				padding: 2em 0 0 0;
			}
			.list_tl {
				display: inline-block;
				width: 25%;
				box-sizing: border-box;
				text-align: center;
				vertical-align: top;
				&.bdr {
					border-right: 1px solid lighten(#90ACAF,20%);
				}
				@include media(740px) {
					width: 50%;
					&:first-child {
						border-bottom: 1px solid lighten(#90ACAF,20%);
					}
					&:nth-child(2) {
						border-bottom: 1px solid lighten(#90ACAF,20%);
					}
				}
				@include media(660px) {
					border: none!important;
					&.production {
						background: $base-color;
						.inner:hover {
							background: lighten($base-color,5%);
						}
					}
					&.mobile {
						background: #F63378;
						.inner:hover {
							background: lighten(#F63378,5%);
						}
					}
					&.graphic {
						background: $point-color;
						.inner:hover {
							background: lighten($point-color,5%);
						}
					}
					&.maintenance {
						background: #F36C35;
						.inner:hover {
							background: lighten(#F36C35,5%);
						}
					}
				}
				.inner {
					display: block;
					text-decoration: none;
					padding: 15% 10%;
					box-sizing: border-box;
					height: 260px;
					@include media(740px) {
						padding: 8% 10%;

					}
					@include media(660px) {
						padding: 2em 10% 2% 10%;
						height: 200px;
					}
					&:hover {
						background: $base-color;
						.icon {color: #fff!important;}
						.list_nav_ttl {color: #fff;}
						.note {color: #fff;}
						.downicon {color: #fff;}
					}
					.list_nav_ttl {
						@include font-size(15);
						font-weight: bold;
						white-space: nowrap;
						padding-top: 2em;
						display: block;
						color: #555;
						@include media(960px) {
							@include font-size(14);
						}
						@include media(768px) {
							@include font-size(13);
							white-space: pre-wrap;
						}
						@include media(660px) {
							padding-top: 1em;
							@include font-size(12);
							color: #fff;
						}
					}
					.note {
						@include font-size(12);
						text-align: left;
						color: #000;
						padding: 1em 0 0 0;
						@include media(660px) {
							@include font-size(10);
							color: #fff;
						}
					}
					.icon {
						font-size: 60px;
						display: block;
						color: $base-color;
						@include media(660px) {
							font-size: 40px;
							display: inline;
							color: #fff!important;
						}
						&.downicon {
							font-size: 20px;
						}
						&.production {
							color: $base-color;
						}
						&.mobile {
							color: #F63378;
						}
						&.maintenance {
							color: #F36C35;
						}
						&.graphic {
							color: $point-color;
						}
					}
				}
			}
		}
	}
	&.infomation {
		display: table;
		width: 100%;
		box-sizing: border-box;
		max-width: 1600px;
		margin: 0 auto;
		@include media(659px) {
			display: block;
		}
		.about {
			display: table-cell;
			width: 50%;
			background: #eee;
			@include media(659px) {
				width: 100%;
				display: block;
			}
			.inner {
				padding: 5%;
			}
			.company_info {
				width: 100%;
				box-sizing: border-box;
				@include font-size(12);
				padding: 0 5%;
				overflow: hidden;
				@include media(659px) {
					padding: 0;
				}
				dt {
					float: left;
					clear: left;
					width: 20%;
					box-sizing: border-box;
					padding: 1% 2%;
					color: $point-color;
					text-align: right;
				}
				dd {
					float: left;
					width: 80%;
					box-sizing: border-box;
					padding: 1% 2%;
					&.small {
						@include font-size(10);
					}
				}
				.callto {
					text-decoration: none;
					display: inline-block;
					border: 1px solid $point-color;
					color: $point-color;
					padding: .5em 1em;
					line-height: 1em;
					border-radius: 1em;
				}
			}
		}
		.contact {
			display: table-cell;
			empty-cells: 50%;
			background: $point-color;
			color: #fff;
			@include media(659px) {
				width: 100%;
				display: block;
			}
			.inner {
				padding: 5%;
			}
			.summary {
				text-align: center;
				@include font-size(14);
				@include media(659px) {
					text-align: left;
					p {
						display: inline;
					}
				}
			}
			.cbtn_wrap {
				text-align: center;
				padding-top: 2em;
				.c_btn {
					border: 1px solid #fff;
					display: inline-block;
					text-decoration: none;
					color: #fff;
					padding: 1em 2em;
					&:hover {
						background: saturate($point-color,10%);
						transition: all 0.2s linear;
					}
				}
			}
			.icon_wrap {
				padding-top: 2em;
				text-align: center;
				img {
					width: 160px;
					margin-left: 15%;
				}
			}
		}
	}
}

.home_price_detail {
	.list_ttl {
		@include font-size(24);
		margin-bottom: 2em;
		@include media(767px) {
			@include font-size(18);
		}
	}
	.pricelist {
		font-size: 12px;
		@include media(660px) {
			display: block;
			tr {
				display: block;
				width: 100%;
				box-sizing: border-box;
			}
			td {
				display: block;
			}
		}
		.item_name {
			width: 25%;
			border-bottom: 1px solid lighten(#90ACAF,15%);
			padding: 1em;
			color: $base-color;
			@include media(660px) {
				width: 100%;
				border: none;
				box-sizing: border-box;
				padding: 1em 1em .2em 1em;
				br {
					display: none;
				}
			}
		}
		.item_price {
			width: 20%;
			border-bottom: 1px solid lighten(#90ACAF,15%);
			padding: 1em;
			@include media(660px) {
				width: 100%;
				border: none;
				box-sizing: border-box;
				padding: .2em 1em .5em 1em;
			}
		}
		.item_de {
			width: 55%;
			border-bottom: 1px solid lighten(#90ACAF,15%);
			padding: 1em;
			@include media(767px) {
				font-size: 10px;
			}
			@include media(660px) {
				width: 100%;
				box-sizing: border-box;
				padding: 0 .5em 1em 1em;
			}
		}
	}
	.plan_wrap {
		background: lighten(#90ACAF,32%);
		overflow: hidden;
	}
	.support_plan {
		font-size: 12px;
		width: 50%;
		box-sizing: border-box;
		float: left;
		td, th {
			border: none;
		}
		@include media(660px) {
			width: 100%;
		}
		.plan_name {
			width: 50%;
			padding: 1em;
			font-weight: normal;
			background: lighten(#90ACAF,30%);
			text-align: center;
			box-sizing: border-box;
			@include media(860px) {
				font-size: 11px;
				padding: 1em .5em;
			}
			@include media(767px) {
				font-size: 10px;
			}
		}
		.plan_price {
			padding: 2em 0;
			text-align: center;
			color: $base-color;
			background: #fafafa;
			.per_price {
				font-family: 'Roboto', sans-serif;
				font-weight: 300;
				text-rendering: auto;
				text-decoration: none;
				font-size: 38px;
				line-height: 1;
				@include media(767px) {
					font-size: 30px;
				}
			}
			.per_month {
				padding-top: .5em;
				@include media(767px) {
					font-size: 10px;
				}
			}
		}
		.plan_name_en {
			background: $base-color;
			color: #fff;
			&.bdr {
				border-color: darken($base-color,5%);
			}
			.plan_ttl {
				text-align: center;
				font-family: 'Roboto', sans-serif;
				font-weight: 300;
				text-rendering: auto;
				text-decoration: none;
				font-size: 24px;
				line-height: 1;
				padding: .5em 0;
				@include media(767px) {
					font-size: 20px;
				}
			}
		}
		.bdr {
			border-left: 1px solid lighten(#90ACAF,25%);
		}
		.plan_detail {
			background: lighten(#90ACAF,32%);
			vertical-align: top;
		}
		.plan_detail_menu {
			li {
				border-bottom: 1px solid lighten(#90ACAF,25%);
				padding: .5em 1em;
				font-size: 11px;
				color: #555;
				text-align: center;
				@include media(767px) {
					font-size: 10px;
					padding: .5em;
				}
			}
		}
	}
}
