@charset "utf-8";
@import "_base.scss";
@import "_mixin.scss";
.draft {
	.section {
		margin-bottom: 4em;
		padding-bottom: 4em;
		border-bottom: 1px solid #eee;
	}
	p {
		margin-bottom: 1em;
	}
	.secttl {
		margin-bottom: 1em;
	}
	.subttl {
		font-weight: bold;
	}
}
