@charset "utf-8";
@import "_base.scss";
@import "_mixin.scss";

/*-----------------------------------------------
 __page_contents
-----------------------------------------------*/

/*----- search form-------*/
.search {
	padding: 20px;
	input[type="text"] {
		border: 1px solid rgba(255,255,255,.5);
		background: rgba(255,255,255,.4);
		border-radius: 0;
		font-size: 16px;
		line-height: 1;
		padding: .5em;
	}
	input[type="submit"] {
		padding: .5em;
		font-size: 16px;
		color: #FFF;
		border: 1px solid $base-color;
		background-color: $base-color;
		background-image: none;
		box-shadow: none;
		border-radius: 0;
		&:hover , &:active{
			color: #FFF;
			background-color: darken($base-color,5%);
			background-image: none;
		}
	}
}


/*----- poricy -------*/
#policy {
	.chapter {
		margin-top: 3em;
		.inner {
			padding: 2em;
		}
		.chpttl {
			@include font-size(20);
			font-weight: bold;
			margin-bottom: 1em;
		}
		.lstttl {
			font-weight: bold;
			margin: 1em 0 .5em 0;
		}
	}
	ul.list_contents {
		padding: 0;
		margin: .5em 0 .5em 2em;
		li {
			padding: 0 0 1em 0;
			margin: 0;
			list-style: circle;
		}
	}
}
